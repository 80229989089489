<template>
  <div>
    <form-section :title="$t('courierSet.setData')">
      <v-row>
        <v-col>
          <text-field
            v-model="getAuthorizationData.configurationName"
            :title="$t('courierSet.configurationName')"
            rules="required|not_custom_shipment"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getAuthorizationData.id"
            :title="$t('courierSet.configurationId')"
            rules="required"
            :disabled="true"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <select-field
            v-model="getAuthorizationData.courierCode"
            :title="$t('courierSet.deliverySpeditor')"
            rules="required"
            :filed-items="SHIPMENT_METHOD_CODES"
            disabled
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.serviceType')">
      <v-row>
        <v-col>
          <select-field
            v-model="getAuthorizationData.serviceType"
            :title=" $t('courierSet.serviceTransportType')"
            rules="required"
            :filed-items="serviceTypes"
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.authData')">
      <v-row>
        <v-col>
          <text-field
            v-model="getAuthorizationData.userName"
            :title="$t('courierSet.login')"
            rules="required"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getAuthorizationData.password"
            :title="$t('courierSet.password')"
            type="password"
            rules="required"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="getAuthorizationData.urlAddress"
            :title="$t('courierSet.urlAddress')"
            :rules="'required|url'"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { serviceTypes } from '@/constants/Speditors/DHL/ServiceTypes';
import { mapGetters } from 'vuex';
import { SHIPMENT_METHOD_CODES } from '@/constants/ShipmentMethodCodes.js';

export default {
  props: {
    isNew: { type: Boolean, default: false },
  },
  data() {
    return {
      SHIPMENT_METHOD_CODES,
      serviceTypes,
    };
  },
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getAuthorizationData() {
      return this.getConfigurationSet();
    },
  },
  mounted() {
    this.getAuthorizationData.courierCode = 'dhl';
    if (this.isNew) {
      this.getAuthorizationData.id = uuidv4();
      this.getAuthorizationData.urlAddress = 'https://sandbox.dhl24.com.pl/webapi2/provider/service.html?ws=1';
    }
  },
};
</script>

<style scoped>
</style>
